import { ConditionModel } from '../ConditionModel';
import {
    type SearchConditionGroup,
    CONDITION_CHANGE_EVENT,
    type ConditionChangeEvent,
    type SearchNode,
} from 'src/features/searchAlpha/types';
import { SEARCH_CONDITION_CHILD_LISTENERS } from 'src/features/searchAlpha/weakMaps';
import { v4 } from 'uuid';

export interface ConditionGroupModelOptions {
    booleanOperator: 'and' | 'or';
    children: (ConditionGroupModel | ConditionModel)[];
    id: string;
    isExpanded: boolean;
    isSelfEnabled: boolean;
    label: string | undefined;
    parent: ConditionGroupModel | false;
}

interface ConditionGroupModelState {
    booleanOperator: 'and' | 'or';
    children: (ConditionGroupModel | ConditionModel)[];
    id: string;
    /**
     * Did a specific property of this group change (not its children)?
     */
    isDirty: boolean;
    isExpanded: boolean;
    isSelfEnabled: boolean;
    isUnsaved: boolean;
    label: string | undefined;
    nestedChildStats: SearchConditionGroup['nestedChildStats'];
    canAdoptInFlightChild: boolean;
}

// TBD: Implement change methods
export class ConditionGroupModel implements SearchConditionGroup {
    changeTarget = new EventTarget();

    #parent: ConditionGroupModel | false;

    #state: ConditionGroupModelState;

    addChild(child: ConditionGroupModel | ConditionModel, index: 'start' | 'end' | number = 'end'): void {
        const children =
            index === 'start'
                ? [child, ...this.#state.children]
                : index === 'end'
                  ? [...this.#state.children, child]
                  : [...this.#state.children.slice(0, index), child, ...this.#state.children.slice(index)];
        this.state = {
            ...this.#state,
            children,
            isDirty: true,
            isUnsaved: true,
            nestedChildStats: this.#getNestedChildStats(children),
        };

        const listener = this.#onChildChange.bind(this);
        SEARCH_CONDITION_CHILD_LISTENERS.set(child, listener);
        child.changeTarget.addEventListener(CONDITION_CHANGE_EVENT, listener);
    }

    addNewCondition() {
        const newCondition = new ConditionModel({
            id: v4(),
            isSelfEnabled: true,
            parent: this,
            conditionState: {
                type: 'non-guided',
                state: {
                    naturalLanguage: '',
                    displayMode: 'language',
                    atom: {
                        status: 'writing',
                    },
                },
            },
        });

        this.addChild(newCondition);
    }

    addNewConditionGroup() {
        const newGroup = new ConditionGroupModel({
            isExpanded: true,
            booleanOperator: 'and',
            isSelfEnabled: true,
            label: '',
            id: v4(),
            children: [],
            parent: this,
        });
        newGroup.addNewCondition();

        this.addChild(newGroup);
        newGroup.setIsDirty(false);
    }

    applyTryOutIdea(idea: string) {
        const addIdeaToGroup = (group: ConditionGroupModel) => {
            const newCondition = new ConditionModel({
                id: v4(),
                isSelfEnabled: true,
                parent: group,
                conditionState: {
                    type: 'non-guided',
                    state: {
                        naturalLanguage: idea,
                        displayMode: 'language',
                        atom: {
                            status: 'writing',
                        },
                    },
                },
            });

            group.addChild(newCondition);
        };

        const groups = this.children.filter((child) => child instanceof ConditionGroupModel);
        const lastGroup = groups.length > 0 ? groups[groups.length - 1] : null;

        if (lastGroup) {
            // Check if last line in group is empty, if it is we will remove it later
            const lastChild = lastGroup.children.length > 0 ? lastGroup.children[lastGroup.children.length - 1] : null;

            // now add the new condition to the last group
            addIdeaToGroup(lastGroup);

            // if our previous check found an empty lastChild we can remove it now
            // we have to remove it after adding the new condition,
            // otherwise the group could be possibly empty and would be removed
            if (lastChild && lastChild instanceof ConditionModel && lastChild.isEmpty) {
                lastGroup.removeChild(lastChild);
            }
        } else {
            const newGroup = new ConditionGroupModel({
                isExpanded: true,
                booleanOperator: 'and',
                isSelfEnabled: true,
                label: '',
                id: v4(),
                children: [],
                parent: this,
            });
            addIdeaToGroup(newGroup);

            this.addChild(newGroup);
        }
    }

    private canAdoptChild(child: ConditionGroupModel | ConditionModel | undefined): boolean {
        if (!child) {
            return false;
        }

        if (child instanceof ConditionModel && !this.#parent) {
            return false;
        }

        if (child === this) {
            return false;
        }

        if (child instanceof ConditionGroupModel) {
            // Prevent adopting itself or any of its ancestors
            let currentParent = this.#parent;
            while (currentParent) {
                if (currentParent === child) {
                    return false;
                }
                currentParent = currentParent.parent;
            }
        }

        return true;
    }

    get canAdoptInFlightChild() {
        return this.#state.canAdoptInFlightChild;
    }

    adoptChild(child: ConditionGroupModel | ConditionModel, index: 'start' | 'end' | number = 'end'): void {
        if (!this.canAdoptChild(child)) {
            return;
        }

        if (child.parent) {
            child.parent.removeChild(child, true);
        }
        child.setParent(this);

        this.addChild(child, index);
    }

    get booleanOperator() {
        return this.#state.booleanOperator;
    }

    get children() {
        return this.#state.children;
    }

    collapse(): void {
        this.state = { ...this.#state, isExpanded: false };
    }

    collapseAll(): void {
        this.state = { ...this.#state, isExpanded: false };
        this.#state.children.forEach((child) => {
            if (child instanceof ConditionGroupModel) {
                child.collapseAll();
            }
        });
    }

    constructor({
        booleanOperator,
        children,
        id,
        isSelfEnabled,
        isExpanded,
        label,
        parent,
    }: ConditionGroupModelOptions) {
        this.#parent = parent;
        this.#state = {
            booleanOperator,
            children,
            id,
            isDirty: false,
            isExpanded,
            isSelfEnabled,
            isUnsaved: false,
            label,
            nestedChildStats: {
                childCount: 0,
                errorCount: 0,
                pendingNlpCount: 0,
                unsubmittedCount: 0,
            },
            canAdoptInFlightChild: false,
        };
    }

    delete() {
        if (!this.#parent) {
            throw new Error('Cannot delete root node');
        }
        this.#parent.removeChild(this);
    }

    expand(): void {
        this.state = { ...this.#state, isExpanded: true };
    }

    expandAll(): void {
        this.state = { ...this.#state, isExpanded: true };
        this.#state.children.forEach((child) => {
            if (child instanceof ConditionGroupModel) {
                child.expandAll();
            }
        });
    }

    findChildById(id: string): SearchNode | null {
        for (const child of this.#state.children) {
            if (child.id === id) {
                return child;
            }

            if (child instanceof ConditionGroupModel) {
                const found = child.findChildById(id);
                if (found) {
                    return found;
                }
            }
        }

        return null;
    }

    get id() {
        return this.#state.id;
    }

    get isDirty() {
        return this.#state.isDirty;
    }

    get isEnabledInSearch(): boolean {
        if (!this.#state.isSelfEnabled) {
            return false;
        }

        if (!this.#parent) {
            return true;
        }

        return this.#parent.isEnabledInSearch;
    }

    get isExpanded() {
        return this.#state.isExpanded;
    }

    get isSelfEnabled() {
        return this.#state.isSelfEnabled;
    }

    get isUnsaved() {
        return this.#state.isUnsaved;
    }

    get isUnsubmitted() {
        return this.#state.isDirty;
    }

    get label() {
        if (this.#state.label !== '') {
            return this.#state.label;
        }

        if (!this.#parent) {
            return 'Root Group'; // should never be hit in practice
        }
    }

    get labelIndex() {
        // Considering we are using the label index to display default group names, this will never be accessed
        if (!this.#parent) {
            return -1;
        }

        return this.#parent.children.findIndex((child) => child.id === this.id);
    }

    get nestedChildStats() {
        return this.#state.nestedChildStats;
    }

    #onChildChange(): void {
        const nestedChildStats = this.#getNestedChildStats(this.#state.children);
        if (
            nestedChildStats.childCount !== this.#state.nestedChildStats.childCount ||
            nestedChildStats.errorCount !== this.#state.nestedChildStats.errorCount ||
            nestedChildStats.pendingNlpCount !== this.#state.nestedChildStats.pendingNlpCount ||
            nestedChildStats.unsubmittedCount !== this.#state.nestedChildStats.unsubmittedCount
        ) {
            this.state = { ...this.#state, nestedChildStats };
        }
        this.changeTarget.dispatchEvent(new CustomEvent(CONDITION_CHANGE_EVENT) satisfies ConditionChangeEvent);
    }

    get parent() {
        if (parent === null) {
            throw new Error('May not access parent on root node');
        }
        return this.#parent;
    }

    processInFlightChild(inFlightItem: ConditionGroupModel | ConditionModel | undefined): void {
        const canAdoptInFlightChild = this.canAdoptChild(inFlightItem);

        this.state = { ...this.#state, canAdoptInFlightChild };
        this.#state.children.forEach((child) => {
            if (child instanceof ConditionGroupModel) {
                child.processInFlightChild(inFlightItem);
            }
        });
    }

    removeChild(child: ConditionGroupModel | ConditionModel, allowEmptyRootGroup = false): void {
        let remainingChildren = this.#state.children.filter((c) => c !== child);

        // when the last child of a group has been removed,
        // we need to delete the group as well,
        // unless this is the root group, we never delete the root group
        if (remainingChildren.length === 0 && this.#parent) {
            this.#parent.removeChild(this);
        }

        // when the last child of the root group has been removed,
        // allowEmptyRootGroup is not true
        // we need to add a new condition group to the root group
        if (remainingChildren.length === 0 && !this.#parent && !allowEmptyRootGroup) {
            this.addNewConditionGroup();

            // the newly added child will be the last one, grab this so we can set it as the new only child of the root group
            const lastChild = this.#state.children.at(-1);
            if (lastChild) {
                remainingChildren = [lastChild];
            }
        }

        this.state = {
            ...this.#state,
            children: remainingChildren,
            isDirty: true,
            isUnsaved: true,
            nestedChildStats: this.#getNestedChildStats(remainingChildren),
        };

        const listener = SEARCH_CONDITION_CHILD_LISTENERS.get(child);
        if (listener) {
            child.changeTarget.removeEventListener(CONDITION_CHANGE_EVENT, listener);
        }
    }

    replaceChild(oldChild: ConditionGroupModel | ConditionModel, newChild: ConditionGroupModel | ConditionModel): void {
        const indexOfChild = this.children.indexOf(oldChild);
        this.adoptChild(newChild, indexOfChild);
        this.removeChild(oldChild);
    }

    setBooleanalabiity(conditionality: 'and' | 'or'): void {
        this.state = { ...this.#state, booleanOperator: conditionality, isDirty: true, isUnsaved: true };
    }

    setEnabled(isEnabled: boolean): void {
        this.state = {
            ...this.#state,
            isDirty: isEnabled ? true : this.#state.isDirty,
            isUnsaved: true,
            isSelfEnabled: isEnabled,
        };
    }

    setIsDirty(isDirty: boolean): void {
        this.state = { ...this.#state, isDirty };
    }

    setIsUnsaved(isUnsaved: boolean): void {
        this.state = { ...this.#state, isUnsaved };
    }

    setLabel(label: string | undefined): void {
        this.state = { ...this.#state, label, isDirty: true, isUnsaved: true };
    }

    setParent(parent: ConditionGroupModel): void {
        this.#parent = parent;
    }

    private set state(newState: ConditionGroupModelState) {
        this.#state = newState;
        this.changeTarget.dispatchEvent(new CustomEvent(CONDITION_CHANGE_EVENT) satisfies ConditionChangeEvent);
    }

    toggleBooleanOperator(): void {
        this.state = {
            ...this.#state,
            isDirty: true,
            isUnsaved: true,
            booleanOperator: this.#state.booleanOperator === 'and' ? 'or' : 'and',
        };
    }

    #getNestedChildStats(children: (ConditionGroupModel | ConditionModel)[]): ConditionGroupModel['nestedChildStats'] {
        return children.reduce(
            (acc, child) => {
                // Groups
                if (child instanceof ConditionGroupModel) {
                    return {
                        childCount: acc.childCount + child.nestedChildStats.childCount,
                        errorCount: acc.errorCount + child.nestedChildStats.errorCount,
                        pendingNlpCount: acc.pendingNlpCount + child.nestedChildStats.pendingNlpCount,
                        unsubmittedCount: acc.unsubmittedCount + child.nestedChildStats.unsubmittedCount,
                    };
                }

                // Lines
                return {
                    childCount: acc.childCount + 1,
                    errorCount: acc.errorCount + (child.errors ? child.errors.length : 0),
                    pendingNlpCount: acc.pendingNlpCount + (child.isPendingNlp ? 1 : 0),
                    unsubmittedCount: acc.unsubmittedCount + (child.isUnsubmitted ? 1 : 0),
                };
            },
            { childCount: 0, errorCount: 0, pendingNlpCount: 0, unsubmittedCount: 0 },
        );
    }
}
